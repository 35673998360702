import '../css'
import './jquery.countdown'
import '../css/jquery.countdown'
import ujs from '@rails/ujs'

const startExam = function() {
  let autoSaveInterval
  let secondsToFinish = parseInt($('#student-exam').attr('data-seconds-to-finish'))

  $('#timeRemaining').countdown({
    until: secondsToFinish,
    onExpiry: function() {
      clearInterval(autoSaveInterval)
      $('#student_exam_form').trigger('submit')
    }
  })

  $('#toCancelAutoSave').countdown({
    until: secondsToFinish - 50,
    onExpiry: function() {
      clearInterval(autoSaveInterval)
    }
  })

  autoSaveInterval = setInterval(function () {
    $('.submitButton').addClass('disabled').attr('disabled', 'disabled')
    $('#autoSavingMessage').text('Auto saving...')

    $.ajax({
      type: 'POST',
      url: $('#student-exam').attr('data-auto-save'),
      data: $('#student_exam_form').serialize(),
      success: function(msg) {
        $('#lastAutoSave').text(msg)
        $('.submitButton').removeClass('disabled').removeAttr('disabled')
        $('#autoSavingMessage').text('')
      }
    })
  }, 60000)
}

$(document).on('change', '.toggle-placeholder', function() {
  $(this).next('input[type=hidden]').val(this.checked ? '0' : '1')
})

// faculty_grade.html.erb
const calculateGrade = function() {
  let total = 0
  const possible = parseFloat($('#points_possible').text())

  $('.points_text_field').each(function() { total += Number($(this).val()) })
  $('#not_saved_message').show()
  $('#graded_total').html(total)

  const percent = Math.round(10000 * total / possible) / 100
  $('#graded_percent').html(percent)
}

$(document).on('change', '.points_text_field', calculateGrade)

$(function() {
  if ($('#graded_total').length > 0) {
    calculateGrade()
  }

  if($('#contentDiv').length > 0) {
    let checkExamLoaded = setInterval(function() {
      let button = document.getElementById('check-exam-loaded')
      if (button) {
        ujs.fire(button, 'click')
      } else {
        clearInterval(checkExamLoaded)
      }
    }, 1000)

    let checkExamStarted = setInterval(function() {
      if (document.getElementById('student-exam')) {
        startExam()
        clearInterval(checkExamStarted)
      }
    }, 500)
  }
})
