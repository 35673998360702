const toggleTimeLimit = function() {
  $('#time-limit').toggle(!$('#exam_is_take_home').is(':checked'))
}

$(document).on('change', '#exam_is_take_home', toggleTimeLimit)

$(document).on('change', '#selectAllExamsCheckbox', function() {
  $('.examCheckbox').prop('checked', $(this).is(':checked'))
})

$(function() {
  $('#sortableExamSections').sortable({
    handle: '.itemHandle',
    stop: function(e, ui) {
      let newSortValue = $(this).sortable('toArray').indexOf($(ui.item).attr('id')) + 1
      let examSectionId = $(ui.item).find('#examSectionId').val()

      $.get(`/exam_sections/${examSectionId}/update_position/${newSortValue}`)
    }
  })

  toggleTimeLimit()
})
