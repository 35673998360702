$(document).on('change', '.answer-rows', function() {
  $(this).parents('.answer-option-div').find('.answer-text-area').attr('rows', $(this).val())
})

$(document).on('change', '.answer-width', function() {
  let el = $(this).parents('.answer-option-div').find('.answer-text-area')

  el.removeClass((ix, klass) => klass.startsWith('col-md-'))
  el.addClass(`col-md-${$(this).val()}`)
})

$(document).on('click', '.editMatchingQuesiton', function() {
  let parent = $(this).parents('.matchingQuestionDiv')
  let modal = $('#newQuestionModal')

  modal.find('.modalExamQuestionId').val(parent.find('#exam_question_id').val())
  modal.find('.modalPoints'        ).val(parent.find('#points').val())
  modal.find('.modalQuestionText'  ).val(parent.find('#question_text').val())

  let imageFileName = parent.find('#image_filename').val()
  modal.find('.modalImageFileName').val(imageFileName)

  if (imageFileName != '') {
    modal.find('.imageLink').text(imageFileName)
    modal.find('.imageLink').attr('href', parent.find('#image_url').val())
    modal.find('.imageLinkSpan').show()
    modal.find('.deleteImage').show()
  }

  modal.modal('show')
})

$(document).on('hide.bs.modal', '#newQuestionModal', function() {
  $(this).find('.modalExamQuestionId').val('')
  $(this).find('.modalQuestionText').val('')
  $(this).find('.errorMessage').html('').removeClass('alert alert-danger')
  $(this).find('.modalImageFileName').val('')
  $(this).find('.imageLink').text()
  $(this).find('.imageLink').attr('href', '#')
})

$(document).on('shown.bs.modal', '#newQuestionModal', function() {
  $(this).find('.modalQuestionText').trigger('focus')
})

$(document).on('click', '.editMatchingAnswer', function() {
  let parent = $(this).parents('.matchingAnswerDiv')
  let modal = $('#newAnswerModal')

  modal.find('.modalAnswerOptionId').val(parent.find('#answer_option_id').val())
  modal.find('.modalAnswerText').val(parent.find('#answer_text').val())
  modal.modal('show')
})

$(document).on('hide.bs.modal', '#newAnswerModal', function() {
  $(this).find('.modalAnswerOptionId').val('')
  $(this).find('.modalAnswerText').val('')
  $(this).find('.errorMessage').html('').removeClass('alert alert-danger')
})

$(document).on('shown.bs.modal', '#newAnswerModal', function() {
  $(this).find('.modalAnswerText').trigger('focus')
})

$(document).on('click', '.switchSectionLink', function() {
  let parent = $(this).parents('.questionRow')
  let modal = $('#switchSectionModal')

  modal.find('.modalExamQuestionId').val(parent.find('.examQuestionId').val())
  modal.find('.modalQuestionNumber').html(parent.find('.questionNumber').html())
  modal.find('.modalQuestionText').html(parent.find('.questionText').html())
  modal.modal('show')
})

$(document).on('hide.bs.modal', '#switchSectionModal', function() {
  $(this).find('.section_id').val('')
  $(this).find('.modalExamQuestionId').val('')
  $(this).find('.modalQuestionNumber').html('')
  $(this).find('.modalQuestionText').html('')
  $(this).find('.errorMessage').html('').removeClass('alert alert-danger')
})

$(function() {
  $('#sortableMatchingQuestions').sortable({
    handle: '.itemHandle',
    stop: function(e, ui) {
      let newPosition = parseInt($('#sortableMatchingQuestions').attr('data-head-position')) + $(this).sortable('toArray').indexOf($(ui.item).attr('id'))
      let examQuestionId = $(ui.item).find('#exam_question_id').val()

      $.get(`/exam_sections/${$('#sortableMatchingQuestions').attr('data-section-id')}/exam_questions/${examQuestionId}/update_position/${newPosition}`, function(data) {
        if (data == 'Reload') location.reload()
      })
    }
  })

  $('#sortableMatchingAnswers').sortable({
    handle: '.itemHandle',
    stop: function(e, ui) {
      let newPosition = $(this).sortable('toArray').indexOf($(ui.item).attr('id')) + 1
      let examAnswerId = $(ui.item).find('#answer_option_id').val()

      $.get(`/exam_sections/${$('#sortableMatchingAnswers').attr('data-section-id')}/exam_matching_answers/${examAnswerId}/update_position/${newPosition}`, function(data) {
        if (data == 'Reload') location.reload()
      })
    }
  })

  $('#sortableExamQuestions').sortable({
    handle: '.itemHandle',
    stop: function(e, ui) {
      let newSortValue = $(this).sortable('toArray').indexOf($(ui.item).attr('id')) + 1
      let examQuestionId = $(ui.item).children('#examQuestionId').val()

      $.get(`/exam_sections/${$('#sortableExamQuestions').attr('data-section-id')}/exam_questions/${examQuestionId}/update_position/${newSortValue}`, function(data) {
        if (data == 'Reload') location.reload()
      })
    }
  })
})
