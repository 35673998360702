$(document).on('click', '#reset-translation', function(e) {
  e.preventDefault()

  let locale = $(this).attr('data-locale')
  let value = $(`.${locale}-value-hidden`).val()

  $(`#${locale}-display`).html(value)
  $(`.${locale}-value-text-area`).val(value)
})

$(document).on('change', '.value-text-area', function() {
  let locale = $(this).attr('data-locale')
  $(`#${locale}-display`).html($(this).val())
})
