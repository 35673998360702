// Chosen
import 'chosen-js'
import 'chosen-js/chosen.css'

const initChosen = function() {
  $('.chzn-select').chosen({ width: '100%' })
  $('.chzn-select-deselectable').chosen({ allow_single_deselect: true, width: '100%' })
}

// Dates and times
import 'tempusdominus-bootstrap-4'
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css'

const generateHex = function(len) {
  const hex = '0123456789abcdef'
  let output = ''
  for (let i = 0; i < len; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length))
  }
  return output
}

const initDateTimePicker = function(parent, resetId) {
  if (!parent) parent = document

  if (resetId) {
    $(parent).find('.datetimepicker-component, .datepicker-component').each(function() {
      this.id = this.id.replace(/(datepicker-.+-)[0-9a-f]{6}/, `$1${generateHex(6)}`)
      this.querySelector('input').dataset.target = `#${this.id}`
      this.querySelector('.input-group-append').dataset.target = `#${this.id}`
    })
  }

  $(parent).find('.datetimepicker-component').datetimepicker({ icons: { time: 'fas fa-clock' } })
  $(parent).find('.datepicker-component').datetimepicker({ format: 'L' })
}

// Cocoon
import '@nathanvda/cocoon'

$(document).on('cocoon:after-insert', '.cocoon-form', function(e, row) {
  initChosen()
  initDateTimePicker(row, true)
})

$(function() {
  initChosen()
  initDateTimePicker()
})
