const confirmPassword = function() {
  let pass = $('#password')
  let passConf = $('#password_confirm')
  let msg = $('#confirmPasswordMessage')

  if (pass.length == 0 || passConf.length == 0) return

  msg.removeClass('text-warning text-danger text-success')
  $('.submitPassword').attr('disabled', 'disabled')

  if (pass.val() == passConf.val() && pass.val().trim().length >= 8) {
    msg.addClass('text-success').html('Password matches')
    $('.submitPassword').removeAttr('disabled')
  } else if (passConf.val().trim() != '') {
    msg.addClass('text-danger').html('Passwords do not match')
  } else {
    msg.addClass('text-warning').html('Confirm password here')
  }
}

$(document).on('change keyup', '#password, #password_confirm', confirmPassword)
$(confirmPassword)
