import '../css'

$(document).on('change', '#selectAllLecturesCheckbox', function() {
  $('.lectureCheckbox').prop('checked', $(this).is(':checked'))
})


$(document).on('change', '.lecture-media input[name$="[content]"]', function() {
  let file = this.files[0]
  file && $(this).siblings('input[name$="[name]"]').val(file.name)
})

$(function() {
  $('#sortableLectureMedias').sortable({
    handle: '.itemHandle',
    stop: function(e, ui) {
      let newSortValue = $(this).sortable('toArray').indexOf($(ui.item).attr('id')) + 1
      let lectureMediaId = $(ui.item).children('#lectureMediaId').val()

      $.get(`/lecture_medias/${lectureMediaId}/update_position/${newSortValue}`)
    }
  })

  $('#sortableLectures').sortable({
    handle: '.itemHandle',
    stop: function(e, ui) {
      let newSortValue = $(this).sortable('toArray').indexOf($(ui.item).attr('id')) + 1
      let lectureId = $(ui.item).children('#lectureId').val()

      $.get(`/courses/${$('#sortableLectures').attr('data-course-id')}/lectures/${lectureId}/update_position/${newSortValue}`)
    }
  })
})
