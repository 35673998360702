const parseNumber = num => parseFloat(num) || 0.0
const formatCurrency = num => `$${num.toFixed(2)}`
const sumNumbers = function(query) {
  let total = 0.0
  $(query).each(function() { total += parseNumber($(this).html()) })
  return total
}

const setTotals = function() {
  let totalLabFee = sumNumbers('.course_lab_fees')
  let totalCreditCharge = sumNumbers('.course_credit_charge')

  $('.total_credit_hours').html(sumNumbers('.course_credit_hours').toFixed(1))
  $('.total_audit_hours').html(sumNumbers('.course_audit_hours').toFixed(1))
  $('.total_lab_fee').html(formatCurrency(totalLabFee))
  $('.total_credit_charge').html(formatCurrency(totalCreditCharge))
  $('.grand_total').html(formatCurrency(totalCreditCharge + totalLabFee))
}

$(document).on('click', 'form.register-local-student input[type="submit"]', function(e) {
  if ($('#student_number').val().length != 10 || !(/\d\d\d-\d\d-\d\d\d/.test($('#student_number').val()))) {
    e.preventDefault()

    alert('Please enter your correct student ID.')
    return
  }

  if ($('#location').val() == '' || $('#program_enrollment').val() == '') {
    e.preventDefault()

    alert('Please enter your address and program enrollment.')
    return
  }

  if ($('.course_div:visible').length == 0) {
    e.preventDefault()

    alert('Please select courses before submitting.')
    return
  }

  if ($('#signature').val().trim().length == 0) {
    e.preventDefault()

    alert('You must sign your name in the signature field.')
    return
  }
})

$(document).on('click', '#removeCourse', function(e) {
  e.preventDefault()
  let courseId = $(this).attr('data-course')

  $(`.credit_hours_${courseId}, .audit_hours_${courseId}, .lab_fee_${courseId}, .credit_charge_${courseId}`).html('')
  $(`.hidden_enrollment_type_${courseId}`).val('')
  $(`#course_${courseId}`).hide()
  setTotals()
})

$(document).on('click', '#uiAddCourse', function(e) {
  e.preventDefault()

  let courseId = $('#courses_list').val()
  let creditOrAudit = $('#creditAuditSelect').val()
  if (courseId == '') return

  let credit_hours = $(`.hidden_course_hours_${courseId}`).val()
  let lab_fee = $(`.hidden_course_lab_fee_${courseId}`).val()
  let credit_charge = credit_hours * $(`#hiddenTerm${creditOrAudit}Cost`).val()

  $(`.hidden_enrollment_type_${courseId}`).val(creditOrAudit)
  $(`.enrollment_type_${courseId}`).html(creditOrAudit)
  $(`.lab_fee_${courseId}`).html(lab_fee)
  $(`.credit_charge_${courseId}`).html(credit_charge.toFixed(2))

  if (creditOrAudit == 'Audit') {
    $(`.credit_hours_${courseId}`).html('0.0')
    $(`.audit_hours_${courseId}`).html(credit_hours)
  } else if (creditOrAudit == 'Credit') {
    $(`.credit_hours_${courseId}`).html(credit_hours)
    $(`.audit_hours_${courseId}`).html('0.0')
  }

  setTotals()
  $(`#course_${courseId}`).show()
  $('#courses_list').val(0)
})

$(document).on('click', 'form.register-student input[type="submit"]', function(e) {
  let totalCost = 0.0
  $('.hiddenCourseCost').each(function() { totalCost += parseFloat($(this).val()) })

  if (totalCost == 0.0) e.preventDefault()

  if (!$('#legalNotice').is(':checked')) {
    e.preventDefault()
    alert($('#legalNotice').attr('data-must-agree'))
    return
  }

  if ($('.courseEnrollmentTypeOptions option:checked[value="Credit"]').length > 0) {
    e.preventDefault()

    let container = $('#confirm-credit-courses')
    $('.courseEnrollmentTypeOptions').each(function() {
      if (this.value == 'Credit') {
        container.append(`
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="confirm-course-${this.getAttribute('data-id')}">
            <label class="form-check-label" for="confirm-course-${this.getAttribute('data-id')}">${this.getAttribute('data-name')}</label>
          </div>
        `)
      }
    })
    $('#confirm-credit-modal').modal('show')
  }
})

$(document).on('change', '#confirm-credit-courses input[type="checkbox"]', function() {
  if ($('#confirm-credit-courses input[type="checkbox"]:not(:checked)').length == 0) {
    $('#confirm-credit').removeAttr('disabled')
  } else {
    $('#confirm-credit').attr('disabled', 'disabled')
  }
})

$(document).on('click', '#confirm-credit', function() {
  $('form.register-student').trigger('submit')
})

$(document).on('hide.bs.modal', '#confirm-credit-modal', function() {
  $('#confirm-credit-courses').empty()
})

$(document).on('change', '.courseEnrollmentTypeOptions', function() {
  let parent = $(this).parents('.row')
  let creditHours = parseFloat(parent.find('.hiddenCourseHours').val())
  let labFee = parseFloat(parent.find('.hiddenCourseLabFee').val())
  let rate = parseFloat(parent.find(`.hiddenTerm${$(this).val()}Cost`).val()) || 0
  let discount_percent = parseFloat(parent.find('.hiddenStudentAuditDiscountPercent').val()) / 100.0

  let cost = (creditHours * rate) + labFee
  let discount = cost * discount_percent
  cost -= discount

  parent.find('.courseCost').html(formatCurrency(cost)).toggleClass('text-muted', cost <= 0).toggleClass('text-danger', cost > 0)
  parent.find('.hiddenCourseCost').val(cost.toFixed(2))

  let totalCost = 0.0
  $('.hiddenCourseCost').each(function() { totalCost += parseFloat($(this).val()) })
  $('.totalCost').html(formatCurrency(totalCost)).toggleClass('text-muted', totalCost <= 0).toggleClass('text-danger', totalCost > 0)
})

const confirmEmail = function() {
  let email = $('#email')
  let emailConf = $('#text_confirm')
  let msg = $('#confirmEmailMessage')

  if (email.length == 0 || emailConf.length == 0) return

  msg.removeClass('text-warning text-danger text-success')
  $('.submitEmail').prop('disabled', true)

  if (email.val() == emailConf.val() && $.trim(email.val()) != '') {
    msg.addClass('text-success').html('E-mail address matches')
    $('.submitEmail').prop('disabled', false)
  } else if (emailConf.val().trim() != '') {
    msg.addClass('text-danger').html('E-mail addresses do not match')
  } else {
    msg.addClass('text-warning').html('Confirm e-mail address here')
  }
}

$(document).on('change keyup', '#email, #text_confirm', confirmEmail)
$(confirmEmail)
