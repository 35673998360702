import ujs from '@rails/ujs'

$(document).on('click', '.clear-search', function(e) {
  e.preventDefault()
  $('.search-form input[name="q"]').val('').trigger('focus')
  ujs.fire($(this).parents('form')[0], 'submit')

  if ($(this).attr('data-url')) {
    history.pushState({}, '', $(this).attr('data-url'))
  }
})

// Give UJS driver a chance to load
$(function() {
  let form = document.querySelector('form.search-on-load')
  if (form) {
    ujs.fire(form, 'submit')
    $(form).find('input[name="page"]').remove() // Remove saved page number so it doesn't pollute subsequent searches
  }
})
