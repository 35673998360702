import { loadScript } from '@paypal/paypal-js'

let redirecting = false

const showError = function(text) {
  if (redirecting) return

  let alert = document.querySelector('#paypal-messages')
  alert.innerText = text
  alert.classList.remove('d-none')
}

const clearError = function() {
  let alert = document.querySelector('#paypal-messages')
  alert.innerText = 'text'
  alert.classList.add('d-none')
}

const processError = (data) => {
  if (data.url) {
    window.location.href = data.url
    redirecting = true
  }
  if (data.error) throw data.error
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('form#new_payment')
  const container = document.querySelector('#paypal-container')
  if (!form || !container) return

  loadScript({ 'client-id': container.getAttribute('data-client') })
  .then(paypal => {
    const spinner = container.querySelector('.fa-spin')
    spinner && spinner.remove()

    const FUNDING_SOURCES = [
      paypal.FUNDING.PAYPAL,
      paypal.FUNDING.CARD
    ]

    FUNDING_SOURCES.forEach(fundingSource => {
      paypal.Buttons({
        fundingSource,
        createOrder: async () => {
          clearError()

          const { ok, data: order } = await fetch(form.action, {
            method: 'POST',
            body: new FormData(form),
          })
          .then(res => res.json().then(data => ({ ok: res.ok, data: data })))

          if (!ok) {
            processError(order)
            return
          }

          return order.id
        },
        onApprove: async (data, actions) => {
          clearError()

          const { ok, data: payment } = await fetch(`${form.action}/${data.orderID}`, {
            method: 'PATCH',
            body: new FormData(form) // Only for authenticity_token
          })
          .then(res => res.json().then(data => ({ ok: res.ok, data: data })))

          if (!ok) {
            processError(payment)
            return
          }

          const error = Array.isArray(payment.details) && payment.details[0]

          if (error) {
            if (error.issue === 'INSTRUMENT_DECLINED') {
              showError('Your payment was declined. Please select a new payment method when prompted.')
              return actions.restart()
            }

            let msg = 'Sorry, your transaction could not be processed.'
            msg += error.description ? ` ${error.description}` : ''
            msg += payment.debug_id ? ` (${payment.debug_id})` : ''
            showError(msg)
          } else {
            window.location.href = '/'
          }
        },
        onError: (error) => {
          showError(error)
        }
      }).render(container)
    })
  })
  .catch(error => {
    showError(`Failed to load PayPal payments: ${error.message}`)
  })
})
