import typeahead from 'typeahead-standalone'
import 'typeahead-standalone/dist/basic.css'

$(function() {
  let el = document.querySelector('.semester-typeahead')
  if (el) {
    typeahead({
      input: el,
      hint: false,
      highlight: true,
      source: {
        local: ['Spring', 'Summer', 'Fall']
      },
    })
  }

  el = document.querySelector('.semester-typeahead-es')
  if (el) {
    typeahead({
      input: el,
      hint: false,
      highlight: true,
      source: {
        local: ['Primavera', 'Verano', 'Otoño']
      },
    })
  }
})
